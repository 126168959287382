const StatisticsData6= [
    {
        id: 1,
        xAxis: ["< 1", "Year"],
        yAxis: "42488"
    },
    {
        id: 2,
        xAxis: "1",
        yAxis: "14467"
    },
    {
        id: 3,
        xAxis: "2",
        yAxis: "12794"
    },
    {
        id: 4,
        xAxis: "3",
        yAxis: "11814"
    },
    {
        id: 5,
        xAxis: "4",
        yAxis: "10877"
    },
    {
        id: 6,
        xAxis: "5",
        yAxis: "10026"
    },
    {
        id: 7,
        xAxis: "6",
        yAxis: "9564"
    },
    {
        id: 8,
        xAxis: "7",
        yAxis: "8858"
    },
    {
        id: 9,
        xAxis: "8",
        yAxis: "8295"
    },
    {
        id: 10,
        xAxis: "9",
        yAxis: "7745"
    },
    {
        id: 11,
        xAxis: "10",
        yAxis: "7362"
    },
    {
        id: 12,
        xAxis: "11",
        yAxis: "7752"
    },
    {
        id: 13,
        xAxis: "12",
        yAxis: "7984"
    },
    {
        id: 14,
        xAxis: "13",
        yAxis: "8983"
    },
    {
        id: 15,
        xAxis: "14",
        yAxis: "9501"
    },
    {
        id: 16,
        xAxis: "15",
        yAxis: "9500"
    },
    {
        id: 17,
        xAxis: "16",
        yAxis: "9169"
    },
    {
        id: 18,
        xAxis: "17",
        yAxis: "6591"
    },
    {
        id: 19,
        xAxis: "18+",
        yAxis: "3079"
    }

]
export default StatisticsData6;