import React from 'react';
import {BarChart} from "components/ui";
import interactiveStatisticsCharts from 'data/interactive-statistics-series-data.js';
import StatisticsData6 from 'data/data-6-enter-out-of-home-care-age-at-removal.js';
import StatisticsEntry from 'templates/statistics-detail-page-layout';

const isBrowser = typeof window !== "undefined"

const StatisticsPage6=({location})=>{
        let thisLocationPathname = ''
        if (isBrowser) {
            thisLocationPathname = window.location.pathname;
        }
        const pageData = interactiveStatisticsCharts.filter(data=>data.id === 2)
        const interactiveStatisticsPageData = pageData[0];
    return (
        <StatisticsEntry
        location={location}
        title={interactiveStatisticsPageData.title}
        description={interactiveStatisticsPageData.metaDescription}
        author={interactiveStatisticsPageData.author}
        year={interactiveStatisticsPageData.year}
        note={<span>Estimates based on <strong className="text-underline">children who entered out of home care</strong> during the Fiscal Year</span>}
        chartNotes={<p>N = 206,849</p>}
        fileName={interactiveStatisticsPageData.fileName}
        fileSize={interactiveStatisticsPageData.fileSize}>
            <BarChart
            title={interactiveStatisticsPageData.title}
            chartLabels={[...StatisticsData6.map(data=>data.xAxis)]}
            chartData={[...StatisticsData6.map(data=>data.yAxis)]}
            xAxisLabel="Age"
            yAxisLabel="Number of children"
            chartLayoutVertical={true}
            yAxisMax={45000}
            yAxisIncrement={5000}
            chartLabelsVertical={true}
            />
            <p>
                Children from birth to age 5 make up nearly half (49.5%, N=102,466) of the children placed in out-of-home care during Fiscal Year 2021. 
                Children age 6 — 12 make up 27.8% (N=57,560) and children age 13 — 18+ make up 22.6% (N=46,823) of the children removed from their homes and placed in out-of-home care during the same time period.
            </p>
        </StatisticsEntry>
    )}
export default StatisticsPage6;